import React, { useEffect } from "react";

// function HandleFormPostRedirect() {
//     useEffect(()=>{
//         console.log(`Evo ga tu smo sada u HandleFormPost`)
//         window.location.replace('https://www.bug.hr')
//     }, [])
// }

class HandleFormPostRedirect extends React.Component {

    // constructor(props) {
        // super(props)
        // this.location = "https://www.bug.hr"
        // this.queryParams = this.props.params
    // }
	componentDidMount() {
		window.location.href = `https://www.bug.hr${this.props.params}`;
	}
	render() {
		return (
			<div>
			</div>
		);
	}
}

export default HandleFormPostRedirect;
